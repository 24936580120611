import React from 'react';

export default () => {
    return (
         <footer className="bg-light py-5">
            <hr/>
          <div className="container">
              <div className="row pt-3 pb-3">
                <div className="col-md-4 text-center">  
                    <h3 className="text-center text-capitalize text-uppercase">About MJ</h3>
                <hr className="divider my-4"/>

                    <ul className="mt-2 text-decoration-none list-unstyled">
                        <li>
                        Living Loving Selling Catalina
                        </li>
                    </ul>
                </div>
                <div className="col-md-4 text-center ">
                    <div>
                        <h3 className="text-center text-capitalize">Address</h3>
                <hr className="divider my-4"/>

                        <p>With Marcanne Green Real Estate CaBRE #01993111  MJ Leonhardi CalBRE #01504725​ </p>
                    </div>

                </div>
                <div className="col-md-4 text-center ">
                    <div>
                        <h3 className="text-center text-capitalize">Socials</h3>
                <hr className="divider my-4"/>

                        <i className="fa fa-facebook-f"><a href="https://www.facebook.com/catalinarealtor"> catalinarealtor</a></i>
                    </div>
                </div>

              </div>
              <hr />
            <div className="small text-center text-muted">Copyright &copy; 2014 - {new Date().getFullYear()} homesbymj
            <br/>
            <b>All rights reserved.</b>
            
            </div>
          </div>
        </footer> 
    )
}

