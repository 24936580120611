import React from 'react';
import {Nav} from 'react-bootstrap';

import {Link} from 'gatsby'
export default (props) => {
    return (
        <Link className="nav-item" to={props.href}>
            <Nav.Link href={props.href}
            >{props.name}</Nav.Link>
          </Link>
          
    )
}