import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'

import NavigationItem from './NavigationItem/NavigationItem'
import Logo from '../Logo/Logo'


export default (props) => {
  console.log(props)
  const navitem = props.list.map(l => {
    if(l.node.site_id === 2){
    return (<NavigationItem key={l.node.id} href={l.node.link} name={l.node.label}/>)
    } else {
      return (<div key={Math.random()*Math.PI}>{' '}</div>)
    }
  })
  return (
    <Navbar
      className="navbar navbar-expand-lg navbar-light fixed-top py-3"
      id="mainNav"
      expand="lg"
      collapseOnSelect={true}
    >
      <div className="container">
        <Logo />
        <Navbar.Toggle aria-controls="navbarResponsive" />
        <Navbar.Collapse id="navbarResponsive">
          <Nav className="navbar-nav ml-auto my-2 my-lg-0">
            {navitem}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}


