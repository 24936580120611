import React from "react"
import Scroller from './scroller'

import NavigationItems from './UI/Navigation/NavigationItems';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this);
  }
  render() {
    return (
      <React.Fragment> 
    <NavigationItems list={this.props.navlist} scoller={Scroller}/>
      </React.Fragment>
    );
  }
}
