import React from 'react';

import {Link} from 'gatsby';

const logo = (props) => {
 return (
    <Link className="navbar-brand" to="/">
        DreamHomesbyMj
    </Link>
 )
};

export default logo;